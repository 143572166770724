@tailwind base;
@tailwind components;
@tailwind utilities;

/* Related To Album Swiper in PDP Page */
.min-h-footer {
  min-height: 150px; /* Adjust based on your footer size */
}
html{
  line-height: 1.5;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.force-size-lg {
  width: 1342px !important;
  height: 362px !important;
}
.golden-bg {
  background-image: url('/imgs/gold_bg_1.webp'); /* Replace with your image URL */
  background-size: cover;
  background-position: center top;
  transition: background-position 1s ease;
}
.golden-bg:hover {
  background-position: center bottom;
}
.force-size-mobile {
  width: 362px !important;
  height: 362px !important;
}
.album-swiper .swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: white !important;
  width: 30px !important;
  height: 3px !important;
  border-radius: 16px !important;
}

.album-swiper .swiper-pagination-bullet-active {
  background-color: #2b2929 !important;
  width: 30px !important;
  height: 3px !important;
  border-radius: 16px !important;
}

/* hide arrow from input[type="number"] */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, currentColor 94%, #0000) top/8px
      8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.loader-xl {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, currentColor 94%, #0000) top/8px
      8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

/* scroll */
.orange-scrollbar::-webkit-scrollbar {
  width: 4px;
}

@media (max-width: 1024px) {
  .orange-scrollbar::-webkit-scrollbar {
    height: 4px;
  }
}

.orange-scrollbar::-webkit-scrollbar-track {
  background: #f57316;
}

.orange-scrollbar::-webkit-scrollbar-thumb {
  background-color: #f57316;
  border-radius: 19px;
  border: 3px none #ffffff;
}

.orange-scrollbar::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.orange-scrollbar::-webkit-scrollbar-thumb {
  background: #f57316;
  border: 0px none #ffffff;
  border-radius: 49px;
}

.orange-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #f57316;
}

.orange-scrollbar::-webkit-scrollbar-thumb:active {
  background: #f57316;
}

.orange-scrollbar::-webkit-scrollbar-track {
  background: #f5f6f6;
  border: 0px none #ffffff;
  border-radius: 0px;
}

.orange-scrollbar::-webkit-scrollbar-track:hover {
  background: #f5f6f6;
}

.orange-scrollbar::-webkit-scrollbar-track:active {
  background: #f5f6f6;
}

.orange-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}

.no_scroll-640::-webkit-scrollbar {
  display: auto;
}

@media screen and (max-width: 640px) {
  .no_scroll-640::-webkit-scrollbar {
    display: none;
  }
}

/* apple pay */
.applePay {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  /* Use any supported button type. */
  cursor: pointer;
  height: 50px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

.applePayBlack {
  -apple-pay-button-style: black;
}

.applePayWhite {
  -apple-pay-button-style: white;
}

.custom-tab-sections ul,
.custom-tab-sections ol {
  list-style-position: inside !important;
  list-style-type: none;
}

.shadow-card {
  box-shadow: 0 0px 6px 1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}